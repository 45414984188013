.alertContainer {
  .success {
    background-color: #43a047;
  }
  
  .error {
    background-color: #d32f2f;
  }
}

.message {
  display: flex;
  align-items: center;
}

.iconVariant {
  opacity: 0.9;
  margin-right: 4px;
}
